import React, {useState, useEffect} from "react"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

import Navbar from "../../components/StudiLive Comps/navbar"
import StudiLiveFooter from "../../components/StudiLive Comps/footer"
import StudiLiveMobileFooter from "../../components/StudiLive Comps/mobileFooter"
import MobileNavbar from "../../components/StudiLive Comps/mobileNavbar"
import useIsMobile from "../../hooks/useIsMobile"
import DatalayerEvents from "../../../services/datalayer"

export default function PrivacyPage(props){
  const isMobile = useIsMobile('1023px');

  const [datalayerEvents, setdatalayerEvents] = useState(new DatalayerEvents())

  useEffect(() => {
    datalayerEvents.init();
  }, [])

  return (
    <div>
      {isMobile ? <MobileNavbar /> : <Navbar />}
      <SEO
        title="Terms and Conditions - Studi Live"
        description="Read about the Terms & conditions of StudiLive with 
      respect to registration with Us, the use of the Application, Website, 
      Services, and products carefully before using the Application, 
      Website, Services or products."
        lang="en"
      />
      <div className="pageBannerHeading">
        <h1 className="displayNone">Terms and Conditions</h1>
        <h2>Terms and Conditions</h2>
      </div>
      <div className="parent_container_desktop">
        <div
          className="terms_desktop_body"
          dangerouslySetInnerHTML={{
            __html: props.data.privacyData.nodes[0]
              .field_privacy_page_body.processed,
          }}
        />
      </div>
      {isMobile ? <StudiLiveMobileFooter /> : <StudiLiveFooter />}
    </div>
  )
}

export const query = graphql`
  query {
    privacyData: allNodeStudilivePrivacyPolicy {
      nodes {
        field_privacy_page_body {
          processed
        }
      }
    }
  }
`
